import type { ReactNode } from 'react';
import React from 'react';
import classNames from 'classnames';

import 'components/common/ActionBar/action-bar.scss';

interface ActionBarProps {
  children: any;
  extraClasses?: string;
}

// eslint-disable-next-line functional/no-class
export default class ActionBar extends React.PureComponent<ActionBarProps, {}> {
  render(): ReactNode {
    const { children, extraClasses } = this.props;
    return (
      <div className={classNames('action-row', extraClasses)} data-testid="action-bar">
        {children}
      </div>
    );
  }
}
