/**
 * `TRACER_COLLECTION_FINISH_MS` is how long after the final request finish to
 * wait for more requests to happen. After this waiting period, the page load is
 * delcared finished.
 */
export const TRACER_COLLECTION_FINISH_MS = 1.5 * 1000;

/**
 * `TRACER_FIRST_PAGE_LOAD_WAIT_MS` is how long to wait for requests to begin
 * after starting the page load span on first load.
 */
export const TRACER_FIRST_PAGE_LOAD_WAIT_MS = 60 * 1000;

/*
 * `TRACER_SUBSEQUENT_PAGE_LOAD_WAIT_MS` is how long to wait for requests to begin
 * after switching pages.
 */
export const TRACER_SUBSEQUENT_PAGE_LOAD_WAIT_MS = 2 * 1000;
