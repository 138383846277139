import type { ReactElement } from 'react';
import React from 'react';
import classNames from 'classnames';

import Button from 'components/common/Button';

export interface ActionItem {
  icon?: ReactElement<any, any>;
  text: string;
  onClick?(): void;
  href?: string;
  rightIcon?: ReactElement<any, any>;
  iconBeforeSrc?: string;
}

interface ActionPopupProps {
  className?: string;
  disabled?: boolean;
  primaryAction: ActionItem;
  hoverActions?: ActionItem[];
  noOutline?: boolean;
}

interface ActionPopupState {
  open: boolean;
}

// eslint-disable-next-line functional/no-class
export default class ActionPopup extends React.PureComponent<ActionPopupProps, ActionPopupState> {
  constructor(props: ActionPopupProps) {
    super(props);
    this.state = {
      open: false,
    };
  }

  handleClose = (): void => {
    this.setState({ open: false });
  };

  handleOpen = (): void => {
    this.setState({ open: true });
  };

  render(): JSX.Element {
    const { className, disabled, hoverActions, primaryAction, noOutline } = this.props;
    const { open } = this.state;
    const extraClasses = className || '';
    return (
      <div
        className={classNames('action-popup', extraClasses)}
        onClick={this.handleClose}
        onMouseLeave={this.handleClose}
        data-testid="action-popup"
      >
        <div className="place-above-parent">
          {open &&
            hoverActions
              ?.filter((action) => action)
              .map((action) => (
                <div className="hover-popup">
                  <Button
                    onClick={action.onClick}
                    href={action.href}
                    className="action-popup-button place-above-parent"
                    key={action.text}
                  >
                    {action.text}
                  </Button>
                  {action.rightIcon}
                </div>
              ))}
        </div>
        <Button
          disabled={disabled}
          key={primaryAction.text}
          icon={primaryAction.icon}
          href={primaryAction.href}
          onClick={primaryAction.onClick}
          onFocus={this.handleOpen}
          onMouseEnter={this.handleOpen}
          className="action-popup-button"
          noOutline={noOutline}
        >
          {primaryAction.text}
        </Button>
        {primaryAction.rightIcon}
      </div>
    );
  }
}
