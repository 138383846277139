export type KeyboardMapping = Record<string, KeyboardShortcut>;

export interface KeyboardShortcut {
  keyCode: number;
  description: string;
  ctrlModifier?: boolean;
  shiftModifier?: boolean;
  altModifier?: boolean;
  clickModifier?: boolean;
  onAction?: (event: KeyboardEvent) => void;
  isCustom: boolean;
  // Customer facing action identifier string, allows customization
  actionId?: string;
  keyString?: string;
}

export const KEYCODE = {
  BACKSPACE: 8,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ESC: 27,
  SPACE: 32,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  SQUARE_BRACKET_LEFT: 219,
  SQUARE_BRACKET_RIGHT: 221,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  A: 65,
  B: 66,
  C: 67,
  D: 68,
  E: 69,
  F: 70,
  H: 72,
  I: 73,
  K: 75,
  L: 76,
  M: 77,
  O: 79,
  P: 80,
  Q: 81,
  R: 82,
  S: 83,
  T: 84,
  U: 85,
  V: 86,
  W: 87,
  Y: 89,
  Z: 90,
  SLASH: 191,
  BACKSLASH: 220,
};

// Used with KeyboardEvent.key because KeyboardEvent.keyCode is deprecated.
export const KEY = {
  ESCAPE: 'Escape',
  ENTER: 'Enter',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
};

export const KEYSTRING = {
  13: 'Enter',
  17: 'Control',
  27: 'Esc',
  32: 'Space',
  37: 'Left Arrow',
  38: 'Up Arrow',
  39: 'Right Arrow',
  40: 'Down Arrow',
  191: '/',
  220: '\\',
};

// This is the keycode for the mouse button that CAUSES the mouse event
// To be used with mouseEvent.button, because mouseEvent.which is deprecated
// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/button
// Usually Primary = left, Auxillary = middle wheel click, Secondary = right
// but this can be edited at the OS level by users.
export enum MouseButton {
  PRIMARY = 0,
  AUXILLARY = 1,
  SECONDARY = 2,
}

// This is a bitwise representation of all mouse buttons currenty being held,
// regardless if they were the one to trigger the mouse event from occuring
// https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/buttons
export enum ButtonMask {
  NONE = 0,
  PRIMARY = 1,
  SECONDARY = 2,
  AUXILLARY = 4,
  FOURTH = 8,
  FIFTH = 16,
}

const IS_MAC = !!window.navigator.platform.match(/^Mac/);
export const Modifier = {
  CTRL: IS_MAC ? '⌘' : 'Ctrl',
  SHIFT: IS_MAC ? '⇧' : 'Shift',
  ALT: IS_MAC ? '⌥' : 'Alt',
  CLICK: 'Click',
};

export function inTextArea(event?: KeyboardEvent): boolean {
  if (
    (event?.target as HTMLInputElement)?.type === 'textarea' ||
    (event?.target as HTMLInputElement)?.type === 'text' ||
    (event?.target as HTMLInputElement)?.type === 'number' ||
    isRichTextEditorContentDiv(event?.target as HTMLElement)
  ) {
    return true;
  }
  return false;
}

function isRichTextEditorContentDiv(element: HTMLElement): boolean {
  return element?.contentEditable === 'true' && element?.getAttribute('role') === 'textbox';
}

export function disableInTextArea(onAction: () => void): (event: KeyboardEvent) => void {
  return (event: KeyboardEvent): void => {
    if (inTextArea(event)) {
      return;
    }
    onAction();
    event?.preventDefault();
  };
}

export function wasPrimaryMouseClick(event: MouseEvent): boolean {
  return event.button === MouseButton.PRIMARY && event.buttons === ButtonMask.PRIMARY;
}

export function keyCodeToString(keyCode: number): string {
  return (
    (KEYSTRING as any)[keyCode] ||
    String.fromCharCode(keyCode >= 96 && keyCode <= 105 ? keyCode - 48 : keyCode)
  );
}

export function getKeyString(shortcut: KeyboardShortcut): string {
  return shortcut.keyString ?? keyCodeToString(shortcut.keyCode);
}

export function ctrlOrCmdKeyPressed(
  event: MouseEvent | KeyboardEvent | React.KeyboardEvent,
): boolean {
  return IS_MAC ? event.metaKey : event.ctrlKey;
}

export function convertShortcutInfo(shortcut: KeyboardShortcut): any {
  const keyString = getKeyString(shortcut);
  const keys = [keyString];
  if (shortcut.shiftModifier) {
    keys.unshift(Modifier.SHIFT);
  }
  if (shortcut.ctrlModifier) {
    keys.unshift(Modifier.CTRL);
  }
  if (shortcut.altModifier) {
    keys.unshift(Modifier.ALT);
  }
  if (shortcut.clickModifier) {
    keys.unshift(Modifier.CLICK);
  }
  return {
    keys,
    description: shortcut.description,
  };
}
