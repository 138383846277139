import type { CSSProperties, FunctionComponent } from 'react';
import React from 'react';
import MaterialButtonShim from 'third_party/material/MaterialButtonShim';

import type { IconProps } from 'components/common/Icon';
import Tooltip from 'components/common/Tooltip';
import { KEYCODE } from 'store/KeyboardManager/shortcut_utils';

import 'components/common/Button/button.scss';

interface ButtonState {
  clicked: boolean;
  top: number;
  left: number;
}

interface ButtonProps {
  className?: string;
  disabled?: boolean;
  icon?: React.ReactElement<React.HTMLProps<HTMLOrSVGElement>> | FunctionComponent<IconProps>;
  noOutline?: boolean;
  onClick?: (ev: React.MouseEvent | React.KeyboardEvent) => void;
  onFocus?: (ev: React.MouseEvent) => void;
  onMouseEnter?: (ev: React.MouseEvent) => void;
  onMouseLeave?: (ev: React.MouseEvent) => void;
  style?: CSSProperties;
  href?: string;
  tooltip?: string;
  /** The 'type' attribute of the button. Defaults to type="button". */
  type?: HTMLButtonElement['type'];
  /* If set to true, clicking the button will not trigger the onClick of parent components. */
  stopPropagation?: boolean;
  target?: string;
  rel?: string;
}

// eslint-disable-next-line functional/no-class
export default class SimianButton extends React.PureComponent<ButtonProps, ButtonState> {
  constructor(props: ButtonProps) {
    super(props);
    this.state = {
      clicked: false,
      top: null! /* Fixme: http://tiny.cc/r4g9vz */,
      left: null! /* Fixme: http://tiny.cc/r4g9vz */,
    };
  }

  onClick = (ev: React.MouseEvent | React.KeyboardEvent): void => {
    const { onClick } = this.props;
    if (onClick) {
      onClick(ev);
    }
  };

  handleKeyDown = (ev: React.KeyboardEvent): void => {
    if (ev.keyCode === KEYCODE.SPACE) {
      this.onClick(ev);
    }
  };

  onMouseMove = (ev: React.MouseEvent): void => {
    this.setState({ top: ev.pageY, left: ev.pageX });
  };

  // Need to do our own mouse down/up/out event handling due to strange
  // focus styling bug in material web design components.
  onMouseDown = (ev: React.MouseEvent): void => {
    ev.preventDefault();
    this.setState({ clicked: true });
  };

  onMouseUp = (ev: React.MouseEvent): void => {
    const { clicked } = this.state;
    this.setState({ clicked: false });
    if (clicked) {
      this.onClick(ev);
    }
  };

  onMouseLeave = (ev: React.MouseEvent): void => {
    const { onMouseLeave } = this.props;
    onMouseLeave?.(ev);
    this.setState({
      clicked: false,
      top: null! /* Fixme: http://tiny.cc/r4g9vz */,
      left: null! /* Fixme: http://tiny.cc/r4g9vz */,
    });
  };

  /**
   * If props.stopPropogation is set to true, then this function is used as the onClick callback.
   * Because this component is relatively unique in that it handles this.onClick() through the
   * onMouseDown and onMouseUp events, we do not need to call this.onClick() here, only
   * stopPropagation().
   */
  stopPropogationOnClick = (ev: React.MouseEvent): void => {
    ev.stopPropagation();
  };

  render(): JSX.Element {
    const {
      children,
      className,
      disabled,
      icon,
      noOutline,
      style,
      onFocus,
      onMouseEnter,
      tooltip,
      href,
      type,
      stopPropagation,
      target,
      rel,
    } = this.props;
    const { top, left } = this.state;
    const extraClasses = className || '';
    // TODO(jamison): Use the 'outlined' prop to determine outlined here.
    const isOutlined = !noOutline;

    return (
      <>
        <MaterialButtonShim
          className={`simian-button button ${extraClasses}`}
          outlined={isOutlined}
          disabled={disabled}
          onClick={stopPropagation ? this.stopPropogationOnClick : undefined}
          onFocus={onFocus}
          onKeyDown={this.handleKeyDown}
          onMouseEnter={onMouseEnter}
          onMouseMove={this.onMouseMove}
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onMouseLeave={this.onMouseLeave}
          icon={icon}
          style={style}
          href={href}
          type={type || 'button'}
          target={target}
          rel={rel}
        >
          {children}
        </MaterialButtonShim>
        <Tooltip top={top} left={left} text={tooltip! /* Fixme: http://tiny.cc/r4g9vz */} />
      </>
    );
  }
}
