export function setCookie(key: string, value: string, daysExpr: number): void {
  const date = new Date();
  date.setTime(date.getTime() + daysExpr * 24 * 60 * 60 * 1000);
  const expires = `; expires=${date.toUTCString()}`;
  const server = window.location.origin;
  const link = document.createElement('a');
  link.href = server;

  // Clear cookies without explicit domain set
  // TODO(Vikas): remove in 1.32 after cookies domains are updated
  document.cookie = `${key}=""; expires=${new Date().toUTCString()}; path=/`;

  // Clear old web service cookie since domain has changed.
  // Otherwise, we will have old login cookies stuck in browsers keeping the user
  // logged in - even when they try to log out.
  if (link.hostname.endsWith(__WEB_SERVICES_DOMAIN__)) {
    document.cookie = `${key}=""; expires=${new Date().toUTCString()}; path=/; domain=.${
      link.hostname
    }`;
  }

  // Set cookie with leading dot on hostname so that cookies are sent on requests
  // to subdomains (primarily grpc.hostname)
  const cookieDomain = getCookieDomainFromHostname(link.hostname);
  document.cookie = `${key}=${value}${expires};path=/;domain=${cookieDomain}`;
}

export function getCookieDomainFromHostname(hostname: string): string {
  // Local host is a special domain - setting subdomain access via a prefix period is invalid.
  if (__IS_DESKTOP__ || hostname === '127.0.0.1' || hostname === 'localhost') {
    return hostname;
  }

  if (hostname.endsWith(__WEB_SERVICES_DOMAIN__)) {
    // For applied.co subdomains, we want to preserve auth across the whole domain rather than
    // only subdomains for the host.
    // For example - if you authenticate on `home.applied.co` it's preferable to stay authenticated on `issue-tracker.applied.co`.
    //
    // NOTE: This logic may apply reasonably to customer domains, but due to lack of clarity
    // we special case here for applied.co.
    const domain_segments = hostname.split('.').reverse();
    const root_domain = domain_segments[1] + '.' + domain_segments[0];
    // Prefix period guarantees all subdomains match the cookie.
    return '.' + root_domain;
  } else {
    // Prefix period guarantees subdomains match the cookie. This is particularly helpful
    // for authenticating gRPC-Web traffic.
    return '.' + hostname;
  }
}

export function getCookie(name: string): string | undefined {
  const cookieSearch = `; ${document.cookie}`.match(`;\\s*${name}=([^;]+)`);
  return cookieSearch ? cookieSearch[1] : undefined;
}

export function getEmailFromAuthTokenCookie(): string {
  const authToken = getCookie('auth_token');

  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return parsed.email;
  } catch (e) {
    return '';
  }
}

export function getUserIdentificationFromAuthTokenCookie(): string[] {
  const authToken = getCookie('auth_token');

  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return [parsed.email].concat(parsed.userGroups ?? []);
  } catch (e) {
    return [];
  }
}

export function getDomainFromAuthTokenCookie(): string {
  try {
    const email = getEmailFromAuthTokenCookie();
    const domain = email.split('@')[1];
    return domain ?? '';
  } catch (e) {
    return '';
  }
}

export function getEditPermissionsFromAuthTokenCookie(): string {
  const authToken = getCookie('auth_token');
  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return parsed.edit_permissions;
  } catch (e) {
    return '';
  }
}

/**
 * @returns A list of all licenced products for the current user. Product names are lower-cased.
 */
export function getProductAccessControlListFromAuthTokenCookie(): string[] {
  const authToken = getCookie('auth_token');
  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return parsed.product_access_list;
  } catch (e) {
    return [];
  }
}

/**
 * @returns A boolean if the admin page is enabled in the auth token
 */
export function getEnableAdminPageFromAuthTokenCookie(): boolean {
  const authToken = getCookie('auth_token');
  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return parsed.enable_admin_page;
  } catch (e) {
    return false;
  }
}

/**
 * @returns A boolean if the cluster status page is enabled in the auth token
 */
export function getEnableClusterStatusDashboardFromAuthTokenCookie(): boolean {
  const authToken = getCookie('auth_token');
  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return parsed.enable_cluster_status_dashboard;
  } catch (e) {
    return false;
  }
}

export function getCompanyEnabledIssueTracking(): boolean {
  const authToken = getCookie('auth_token');
  try {
    // The JWT cookie contains three parts separated by '.'. The second contains a
    // JSON of the info, so we need to split it then parse it here
    const parsed = JSON.parse(
      atob(
        authToken! /* Fixme: http://tiny.cc/r4g9vz */
          .split('.')[1],
      ),
    );
    return parsed.enable_issue_tracking;
  } catch (e) {
    return false;
  }
}
