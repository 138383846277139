import React from 'react';
import { Portal } from 'react-portal';
import classNames from 'classnames';

import 'components/common/Tooltip/tooltip.scss';

export interface TooltipProps {
  /** CSS class name. */
  className?: string;
  /** One-to-one mapping to the CSS property. */
  left: number | null;
  /** Text contents of the tooltip. */
  // TODO(sachit): Rename from `text` to `contents`.
  text: string | JSX.Element;
  /** One-to-one mapping to the CSS property. */
  top: number | null;
  /** Top aligns the tooltip relative to the cursor. */
  openDown?: boolean;
  /** Right aligns the tooltip relative to the cursor. */
  openLeft?: boolean;
  /** Center aligns the tooltip relative to the cursor. */
  openCenter?: boolean;
  /** One-to-one mapping to the CSS property */
  color?: string;
  /** One-to-one mapping to the CSS property */
  backgroundColor?: string;
}

interface TooltipStyle {
  left: string;
  top: string;
  transform?: string;
  color?: string;
  backgroundColor?: string;
}

// eslint-disable-next-line functional/no-class
export default class Tooltip extends React.PureComponent<TooltipProps> {
  render(): JSX.Element {
    const { className, top, left, text, openDown, openLeft, openCenter, color, backgroundColor } =
      this.props;
    if (
      top === null ||
      top === undefined ||
      left === null ||
      left === undefined ||
      !text ||
      !Number.isFinite(top) ||
      !Number.isFinite(left)
    ) {
      return <></>;
    }

    // Apply 2px shifts so tooltip doesn't overlap with cursor.
    const style: TooltipStyle = {
      left: `${left + 2}px`,
      top: `${top - 2}px`,
      color,
      backgroundColor,
    };
    if (!openDown) {
      style.transform = 'translateY(-100%)';
    }
    if (left > window.innerWidth - 100 || openLeft) {
      style.transform = 'translateX(-100%) translateY(-100%)';
    } else if (openCenter) {
      style.transform = 'translateX(-50%) translateY(-100%)';
    }
    if (top < 20) {
      style.transform = 'translateX(-100%)';
    }

    return (
      <Portal>
        <div className={classNames('simian-tooltip', className)} style={style}>
          {text}
        </div>
      </Portal>
    );
  }
}
