// @generated by protobuf-ts 2.9.3 with parameter ts_nocheck,add_pb_suffix,keep_enum_prefix
// @generated from protobuf file "web_services/accounts/proto/authentication.proto" (package "applied.accounts", syntax proto3)
// tslint:disable
// @ts-nocheck
/**
 * @generated from protobuf enum applied.accounts.LoginPageLoginMethod
 */
export enum LoginPageLoginMethod {
    /**
     * @generated from protobuf enum value: GOOGLE = 0;
     */
    GOOGLE = 0,
    /**
     * For backward compatibility
     *
     * @generated from protobuf enum value: SAML = 1;
     */
    SAML = 1,
    /**
     * For backward compatibility
     *
     * @generated from protobuf enum value: SAML = 1;
     */
    CUSTOM_SSO = 1,
    /**
     * @generated from protobuf enum value: APPLIED = 2;
     */
    APPLIED = 2
}
