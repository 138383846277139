import React from 'react';
import type { Props } from 'react-inlinesvg';
import SVG from 'react-inlinesvg';

export interface SVGProps extends Props {
  src: string;
}

const AppliedSVG: React.FunctionComponent<SVGProps> = (props) => (
  <SVG cacheGetRequests {...props} />
);

export default AppliedSVG;
