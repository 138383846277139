import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import { autobind } from 'core-decorators';

import ActionBar from 'components/common/ActionBar';
import ActionPopup from 'components/common/ActionBar/ActionPopup';
import type ModalPromise from 'components/common/Modal/ModalPromise';
import { ModalResponse } from 'components/common/Modal/ModalPromise';
import { KEYCODE } from 'store/KeyboardManager/shortcut_utils';

import 'components/common/Modal/modal.scss';

export interface ModalProps {
  show: boolean;
  header?: string;
  content?: string | JSX.Element;
  isCodeContent?: boolean;
  message?: string | JSX.Element;
  errorText?: string;
  extraClasses?: string;
  extraOverlayClasses?: string;
  onClose?(): void;
  onPrimaryButtonClick?(): void;
  primaryButtonDisabled?: boolean;
  primaryButtonText?: string;
  onSecondaryButtonClick?(): void;
  secondaryButtonDisabled?: boolean;
  secondaryButtonText?: string;
  onTertiaryButtonClick?(): void;
  tertiaryButtonDisabled?: boolean;
  tertiaryButtonText?: string;
  modalPromise?: ModalPromise;
  shouldNotCloseOnOverlayClick?: boolean;
  icon?: JSX.Element;
  shouldNotPrimaryOnEnter?: boolean;
}

// eslint-disable-next-line functional/no-class
export default class Modal extends React.PureComponent<ModalProps> {
  componentDidMount(): void {
    const { show } = this.props;
    if (show) {
      document.addEventListener('keydown', this.handleKeyDown, false);
    }
  }

  componentDidUpdate(prevProps: Readonly<ModalProps>): void {
    const { show } = this.props;
    if (show && !prevProps.show) {
      document.addEventListener('keydown', this.handleKeyDown, false);
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  closeModal = (): void => {
    const { onClose } = this.props;
    document.removeEventListener('keydown', this.handleKeyDown, false);
    if (onClose !== undefined) {
      onClose();
    }
  };

  @autobind
  modalPrimary(): void {
    const { modalPromise, onPrimaryButtonClick } = this.props;
    if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    }
    if (modalPromise) {
      modalPromise.resolve(ModalResponse.primary);
    }
  }

  @autobind
  modalSecondary(): void {
    const { modalPromise, onSecondaryButtonClick } = this.props;
    if (onSecondaryButtonClick) {
      onSecondaryButtonClick();
    }
    if (modalPromise) {
      modalPromise.resolve(ModalResponse.secondary);
    }
  }

  @autobind
  modalTertiary(): void {
    const { modalPromise, onTertiaryButtonClick } = this.props;
    if (onTertiaryButtonClick) {
      onTertiaryButtonClick();
    }
    if (modalPromise) {
      modalPromise.resolve(ModalResponse.tertiary);
    }
  }

  @autobind
  handleKeyDown(event: KeyboardEvent): void {
    const { show, primaryButtonDisabled, onPrimaryButtonClick, shouldNotPrimaryOnEnter } =
      this.props;
    if (!show) {
      return;
    }
    switch (event.keyCode) {
      case KEYCODE.ENTER:
        // If disabled, break.
        if (shouldNotPrimaryOnEnter) {
          break;
        }
        // Ignore enter key event when shift is pressed so other event
        // listeners can handle it.
        if (event.shiftKey) {
          break;
        }
        // Regardless of whether the user can click the primary button,
        // prevent default enter actions to prevent confusing behavior
        // where enter key sometimes works with other event listeners.
        event.preventDefault();
        if (!primaryButtonDisabled && onPrimaryButtonClick) {
          this.modalPrimary();
        }
        break;
    }
  }

  render(): JSX.Element | null {
    const {
      children,
      show,
      header,
      message,
      extraClasses,
      extraOverlayClasses,
      primaryButtonText,
      secondaryButtonText,
      tertiaryButtonText,
      primaryButtonDisabled,
      secondaryButtonDisabled,
      tertiaryButtonDisabled,
      content,
      errorText,
      isCodeContent,
      shouldNotCloseOnOverlayClick,
      icon,
    } = this.props;
    let { onPrimaryButtonClick, onSecondaryButtonClick, onTertiaryButtonClick } = this.props;
    if (!show) {
      return null;
    }

    onPrimaryButtonClick = primaryButtonDisabled ? (): void => {} : this.modalPrimary;
    onSecondaryButtonClick = secondaryButtonDisabled ? (): void => {} : this.modalSecondary;
    onTertiaryButtonClick = tertiaryButtonDisabled ? (): void => {} : this.modalTertiary;

    const actionBar = (
      <ActionBar extraClasses="planar">
        {tertiaryButtonText && (
          <ActionPopup
            disabled={tertiaryButtonDisabled}
            key="ternary-button"
            className="ternary-button"
            primaryAction={{
              text: tertiaryButtonText,
              onClick: onTertiaryButtonClick,
            }}
          />
        )}
        {secondaryButtonText && (
          <ActionPopup
            disabled={secondaryButtonDisabled}
            className="secondary-button"
            key="secondary-button"
            primaryAction={{
              text: secondaryButtonText,
              onClick: onSecondaryButtonClick,
            }}
          />
        )}
        {primaryButtonText && (
          <ActionPopup
            disabled={primaryButtonDisabled}
            className="primary-button"
            key="primary-button"
            primaryAction={{
              text: primaryButtonText,
              onClick: onPrimaryButtonClick,
            }}
          />
        )}
      </ActionBar>
    );
    return (
      <ReactModal
        isOpen={show}
        className={classNames('modal ', extraClasses)}
        overlayClassName={classNames('modal-overlay', extraOverlayClasses)}
        onRequestClose={this.closeModal}
        shouldCloseOnOverlayClick={!shouldNotCloseOnOverlayClick}
      >
        <div className="modal-content">
          {header && (
            <h2 className="modal-title">
              {icon}
              {header}
            </h2>
          )}
          {message && <p className="modal-message">{message}</p>}
          {content && (
            <div className={classNames('modal-inner-content', { monospace: isCodeContent })}>
              {content}
            </div>
          )}
          {children}
          {errorText && <p className="error-text">{errorText}</p>}
        </div>
        {actionBar}
      </ReactModal>
    );
  }
}
