import type { CSSProperties } from 'react';
import React from 'react';
import TextField, { HelperText, Input } from '@material/react-text-field';
import classNames from 'classnames';
import { autobind } from 'core-decorators';

import Tooltip from 'components/common/Tooltip';

import 'components/common/TextField/text-field.scss';

export interface SimianTextFieldProps {
  caption?: string;
  className?: string;
  disabled?: boolean;
  helperText?: string;
  inputClassName?: string;
  textfieldClassName?: string;
  isValid?(value: string | number): boolean;
  label?: string;
  lineRippleClassName?: string;
  onBlur?: any;
  onChange?: (value: string) => void;
  outlined?: any;
  readOnly?: any;
  style?: CSSProperties;
  textarea?: boolean;
  type?: 'string' | 'number';
  value?: any;
  tooltip?: string;
  step?: number;
  max?: number;
  min?: number;
  invalidChars?: string[];
  autoFocus?: boolean;
}

interface SimianTextFieldState {
  currentValue: string;
  tooltipTop: number;
  tooltipLeft: number;
}

/**
 * Applied wrapper around a material text field.
 * See https://github.com/material-components/material-components-web-react/tree/master/packages/text-field
 */
// eslint-disable-next-line functional/no-class
export default class SimianTextField extends React.PureComponent<
  SimianTextFieldProps,
  SimianTextFieldState
> {
  inputRef: any = {};

  state: SimianTextFieldState = {
    currentValue: '',
    tooltipTop: null! /* Fixme: http://tiny.cc/r4g9vz */,
    tooltipLeft: null! /* Fixme: http://tiny.cc/r4g9vz */,
  };

  componentDidMount(): void {
    const { value } = this.props;
    document.addEventListener('mousedown', this.handleClick, false);
    document.addEventListener('keydown', this.handleKeyDown, false);
    this.setState({
      currentValue: value,
    });
  }

  componentDidUpdate(prevProps: SimianTextFieldProps): void {
    const { value } = this.props;
    // If the value has changed display this value instead of the current
    // userInput.
    if (prevProps.value !== value) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        currentValue: value,
      });
    }
  }

  componentWillUnmount(): void {
    document.removeEventListener('mousedown', this.handleClick, false);
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  @autobind
  onMouseMove(ev: React.MouseEvent): void {
    this.setState({ tooltipTop: ev.pageY, tooltipLeft: ev.pageX });
  }

  @autobind
  onMouseLeave(): void {
    this.setState({
      tooltipTop: null! /* Fixme: http://tiny.cc/r4g9vz */,
      tooltipLeft: null! /* Fixme: http://tiny.cc/r4g9vz */,
    });
  }

  handleKeyDown = (ev: KeyboardEvent): void => {
    const { invalidChars } = this.props;
    if (!invalidChars) {
      return;
    }
    const inputElement = this.inputRef.inputElement;
    if (inputElement?.contains(ev.target) && invalidChars.includes(ev.key)) {
      ev.preventDefault();
    }
  };

  handleClick = (ev: MouseEvent): void => {
    const inputElement = this.inputRef.inputElement;
    if (inputElement && inputElement) {
      if (!inputElement.contains(ev.target)) {
        // Clicking outside input, so blur it.
        inputElement.blur();
      }
    }
  };

  onChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;
    this.setState({
      currentValue: ev.target.value,
    });
    if (onChange) {
      onChange(ev.target.value);
    }
  };

  onBlur = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const { onBlur } = this.props;
    if (onBlur) {
      this.setState({
        currentValue: onBlur(ev.target.value),
      });
    }
  };

  render(): JSX.Element {
    const {
      className,
      style,
      label,
      helperText,
      caption,
      type,
      lineRippleClassName,
      isValid,
      readOnly,
      inputClassName,
      textfieldClassName,
      outlined,
      textarea,
      disabled,
      step,
      max,
      min,
      tooltip,
      autoFocus,
    } = this.props;
    const { currentValue, tooltipTop, tooltipLeft } = this.state;
    return (
      <div
        style={style}
        className={classNames('text-field-wrapper', className, {
          'text-field-wrapper--no-helper-text': !helperText,
        })}
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}
      >
        {caption && <div className="caption">{caption}</div>}
        <TextField
          helperText={helperText && <HelperText>{helperText}</HelperText>}
          lineRippleClassName={lineRippleClassName}
          outlined={outlined}
          label=""
          textarea={textarea}
          className={textfieldClassName}
        >
          <Input
            type={type || 'text'}
            value={currentValue}
            disabled={disabled}
            placeholder={label}
            onChange={this.onChange}
            readOnly={readOnly}
            step={step}
            max={max}
            min={min}
            onBlur={this.onBlur}
            isValid={isValid ? isValid(currentValue) : true}
            // TODO(geiersbach): Use createRef instead of a callback ref.
            ref={(input: any): void => {
              this.inputRef = input;
            }}
            className={inputClassName}
            autoFocus={autoFocus}
          />
        </TextField>
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          text={tooltip! /* Fixme: http://tiny.cc/r4g9vz */}
        />
      </div>
    );
  }
}
