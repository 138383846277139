import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LoginRegister from 'components/LoginRegister';

import 'styles/applied-main.scss';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route component={LoginRegister} />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root'),
);
