import type { ParsedQuery } from 'query-string';
import {
  TRACER_COLLECTION_FINISH_MS,
  TRACER_FIRST_PAGE_LOAD_WAIT_MS,
  TRACER_SUBSEQUENT_PAGE_LOAD_WAIT_MS,
} from 'tracing/constants';
import { Span } from 'tracing/span';

export interface SpanInfo {
  span: Span;
  tentativeDurationMs: number;
}

export interface TracingOptions {
  trace: boolean; // Default true;
}

export const getProductFromPathname = (pathname?: string): string => {
  // We guard against pathname here because some tests pass in invalid
  // histories, causing this to be undefined sometimes.
  const parts = pathname?.split('/');
  if (pathname && parts && parts.length > 1) {
    const product = parts[1];
    return product === '' ? 'unknown' : product;
  }
  return 'unknown';
};

export class Tracer {
  /**
   * A list of promises which were a part of the current traced action.
   */
  private promises: Array<PromiseLike<any>> = [];

  /**
   * The last 'interesting' request finish time. Because we want to stop
   * collecting at a certain point, we need to capture the time at which the
   * final request finished.
   */
  private lastActionFinish: number = Date.now();

  /**
   * The timeout id of the current span's finish timeout. Canceled if we see a
   * new request.
   */
  private currentSpanFinish: ReturnType<typeof setTimeout> | undefined;

  /**
   * The curernt route we're on. We rely on some react component to update us of
   * this.
   */
  private currentPathname: string | undefined;
  /**
   * The curernt search we have active.
   */
  private currentSearch: string | undefined;
  /**
   * The current search params.
   */
  private currentParsedSearch: ParsedQuery | undefined;

  /**
   * The current page load span. Kept around after finish so that it can be
   * referenced.
   */
  private pageLoadSpan: Span | undefined;

  /**
   * Listeners of the current page load. We call these when the page load
   * changes.
   */
  private pageLoadListeners: Array<() => void> = [];

  /**
   * The number of requests we've seen per request name, categorized by whether
   * that request was traced or not.
   */
  private requests: Record<'untraced' | 'traced', Record<string, number>> = {
    traced: {},
    untraced: {},
  };

  /**
   * requestListeners contains listeners of the requests object. Whenever we
   * change the requests object, we call these listeners.
   */
  private requestListeners: Array<() => void> = [];

  private untracedNetworkRequests: Set<string> = new Set();

  constructor() {
    // We are constructed when our bundle is loaded. Ideally we would start even
    // before that point, but that would require sending a different bundle, too.
    this.startPageLoad(true);
  }

  private startPageLoad(isFirst: boolean): void {
    // Cancel previous page load if we receive a new one.
    if (this.pageLoadSpan) {
      this.cancelPageLoad();
    }

    this.pageLoadSpan = new Span('pageload');
    this.clearTimeouts();
    this.lastActionFinish = Date.now();

    // Clear requests.
    this.requests.traced = {};
    this.requests.untraced = {};

    this.currentSpanFinish = setTimeout(
      () => {
        // Cancel if we received no network requests.
        if (this.promises.length === 0) {
          this.cancelPageLoad();
        }
      },
      isFirst ? TRACER_FIRST_PAGE_LOAD_WAIT_MS : TRACER_SUBSEQUENT_PAGE_LOAD_WAIT_MS,
    );
  }

  /**
   * getRequests returns the number of requests we've seen per request name,
   * categorized by whether it was traced or not.
   */
  getRequests(): {
    summaries: Record<'untraced' | 'traced', Record<string, number>>;
    sumTraced: number;
    sumUntraced: number;
  } {
    let sumTraced = 0;
    for (const name in this.requests.traced) {
      sumTraced += this.requests.traced[name];
    }
    let sumUntraced = 0;
    for (const name in this.requests.untraced) {
      sumUntraced += this.requests.untraced[name];
    }
    return {
      summaries: this.requests,
      sumTraced,
      sumUntraced,
    };
  }

  /**
   * `getActiveSpan` returns the current span, if any. We may add different
   * kinds of spans later.
   */
  private getActiveSpan(): undefined | Span {
    if (this.pageLoadSpan && this.pageLoadSpan.endMs === undefined) {
      return this.pageLoadSpan;
    }
    return undefined;
  }

  private clearTimeouts(): void {
    if (this.currentSpanFinish) {
      clearTimeout(this.currentSpanFinish);
      this.currentSpanFinish = undefined;
    }
  }

  /**
   * `trace` includes the request in our page analytics. It counts the request by
   * its name which is made available by `getRequests`, and if the request is
   * `trace`d, the request is included underneath the page load span.
   */
  async trace<T>(name: string, promise: PromiseLike<T>, options?: TracingOptions): Promise<T> {
    // Track the request.
    const relevantRequests =
      options?.trace === false ? this.requests.untraced : this.requests.traced;
    relevantRequests[name] = (relevantRequests[name] ?? 0) + 1;
    this.updateRequestListeners();

    // Do nothing if we were instructed not to trace. Pollers will avoid
    // polluting tracers in this way.
    if (options?.trace === false) {
      return promise;
    }

    if (this.getActiveSpan() === undefined) {
      // Received a network request unrelated to a span. Potential polling
      // request, or a user interaction. We don't currently surface these
      // requests as being special, since under normal circumstances we do
      // expect some non-polling requests outside of page load, for example
      // searching a table. We may want to later surface these requests as being
      // untraced if we want to add user interaction tracing to the
      // functionality here.
      this.untracedNetworkRequests.add(name);
      return promise;
    }

    // Cancel any previous timeout.
    this.clearTimeouts();

    // TODO maybe timeout promises that take too long, 60s?

    this.promises.push(promise);

    Promise.allSettled(this.promises).then((values) => {
      // Check that we haven't received new promises since we kicked off the
      // promise. If we have, do nothing. They'll report the page finish.
      if (values.length !== this.promises.length) {
        return;
      }

      this.lastActionFinish = Date.now();
      this.updatePageLoadListeners();

      this.currentSpanFinish = setTimeout(() => {
        // Check again that we have no new promises.
        if (values.length !== this.promises.length) {
          return;
        }
        // If we are the last, finish.
        this.finishActiveSpan();
      }, TRACER_COLLECTION_FINISH_MS);
    });

    // Start a sub-span underneath the page load. Effectively we should always
    // have a page load already.
    const parent = this.getActiveSpan();
    const span: Span = parent ? parent.start(name) : new Span(name);

    // Read the promise, so we can tag the span with the error if it fails.
    try {
      return await promise;
    } catch (e: unknown) {
      // Capture, then throw, the error.
      span.setTag('error', String(e));
      throw e;
    } finally {
      // End the span when that request finishes.
      span.end();
    }
  }

  /**
   * We cancel a page load if we received no network requests related to it.
   */
  private cancelPageLoad(): void {
    if (
      this.pageLoadSpan === undefined ||
      this.pageLoadSpan.canceled ||
      this.pageLoadSpan.endMs !== undefined
    ) {
      // If we aren't currently tracing a page load, do nothing.
      return;
    }
    this.pageLoadSpan.cancel();

    // Update listeners.
    this.updatePageLoadListeners();
  }

  private finishActiveSpan(): void {
    const currentSpan = this.getActiveSpan();
    if (currentSpan === undefined || currentSpan.canceled) {
      return;
    }

    this.clearTimeouts();

    // End at the last action finish, rather than the current time.
    currentSpan.end(this.lastActionFinish);

    this.updatePageLoadListeners();
  }

  /**
   * `getPageLoadSpanInfo` returns the current page load span and its estimated
   * durationMs. If the span has finished, span.endMs will be set and equal to
   * tentativeDurationMs.
   */
  getPageLoadSpanInfo(): undefined | SpanInfo {
    return this.pageLoadSpan
      ? {
          span: this.pageLoadSpan,
          tentativeDurationMs: this.lastActionFinish - this.pageLoadSpan.startMs,
        }
      : undefined;
  }

  /**
   * `onChangePageLoad` registers the callback, and invokes it immediately and
   * whenever the page load changes. This should be used sparingly, if we have
   * too many subscribers we may be finring too many events.
   */
  onChangePageLoad(callback: () => void): void {
    this.pageLoadListeners.push(callback);

    // Invoke immediately.
    callback();
  }

  private updatePageLoadListeners(): void {
    for (const listener of this.pageLoadListeners) {
      listener();
    }
  }

  /**
   * `onUpdateRequests` registers the callback, and invokes it immediately and
   * whenever we update the requests we've seen. This should be used sparingly,
   * if we have too many subscribers we may be finring too many events.
   */
  onUpdateRequests(callback: () => void): void {
    this.requestListeners.push(callback);

    // Invoke immediately.
    callback();
  }

  private updateRequestListeners(): void {
    for (const listener of this.requestListeners) {
      listener();
    }
  }

  /**
   * `notifyOfRouteChange` is how this tracer knows that the route has changed.
   * We rely on this method being invoked when the route changes. This may or
   * may not start a new page load span, depending on the route change and if
   * one already exists or not.
   */
  notifyOfRouteChange(routeParams: {
    pathname: string;
    search: string;
    parsedSearch: ParsedQuery;
  }): void {
    // TODO handle redirects, which are a valid user experience but this tracer
    // does not capture well. Or maybe it does, idk.

    if (
      this.currentPathname === routeParams.pathname &&
      this.currentParsedSearch &&
      routeParams.parsedSearch.selectedTab === this.currentParsedSearch.selectedTab
    ) {
      // Nothing actually changed on our route, so we don't need a new span.
      return;
    }

    // Check if we're on our first route.
    const isFirstLoad = this.currentPathname === undefined;
    if (isFirstLoad && !this.pageLoadSpan) {
      // No page load span, did we set the route super late somehow?
      return;
    }

    // Set local state.
    this.currentPathname = routeParams.pathname;
    this.currentSearch = routeParams.search;
    this.currentParsedSearch = routeParams.parsedSearch;

    // If isFirstLoad, the page load has already been started for us. Otherwise,
    // reset some state and start a new one.
    if (!isFirstLoad) {
      this.promises = [];
      this.lastActionFinish = Date.now();

      this.startPageLoad(false);
    }

    this.setBasicTags();

    // First page loads are much slower.
    this.pageLoadSpan?.setTag('is_first', isFirstLoad);
  }

  /**
   * `setBasicTags` sets some basic tags on the current active span.
   */
  private setBasicTags(): void {
    const span = this.getActiveSpan();
    if (!span) {
      return;
    }

    span.setTag('product', getProductFromPathname(this.currentPathname));
    span.setTag('search', this.currentParsedSearch);
    span.setTag('pathname', this.currentSearch);
    span.setTag('is_development', __IS_DEVELOPMENT__);
  }
}

const tracer = new Tracer();

/**
 * `getTracer` returns the tracer singleton.
 */
const getTracer = (): Tracer => tracer;

export { getTracer };
export type { Span };
