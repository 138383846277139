import fetch from 'isomorphic-fetch';
import type { TracingOptions } from 'tracing/tracing';
import { getTracer } from 'tracing/tracing';

import { getEmailFromAuthTokenCookie } from 'utils/cookies';

const tracer = getTracer();

interface Headers {
  [name: string]: string;
}

export function getTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function getAccountsUrl(path: string): string {
  if (path.startsWith('/')) {
    return `${__ACCOUNTS_URL__}${path}`;
  }
  return `${__ACCOUNTS_URL__}/${path}`;
}

export function getTrackingUrl(path: string): string {
  if (path.startsWith('/')) {
    return `${__TRACKING_URL__}${path}`;
  }
  return `${__TRACKING_URL__}/${path}`;
}

export function getIssueTrackerUrl(path: string): string {
  if (path.startsWith('/')) {
    return `${__ISSUE_TRACKER_URL__}${path}`;
  }
  return `${__ISSUE_TRACKER_URL__}/${path}`;
}

function safelyGetPathname(url: string): string {
  try {
    const pathname = new URL(url).pathname;
    if (pathname.startsWith('/')) {
      return pathname.slice(1);
    }
    return pathname;
  } catch (e) {
    return url;
  }
}

export interface RestRequestOptions {
  tracingOptions?: TracingOptions;
}

export async function getData<ResponseT = any>(
  url: string,
  withCredentials = false,
  requestOptions?: RestRequestOptions,
): Promise<ResponseT> {
  return tracer.trace(
    'get_' + safelyGetPathname(url),
    (async (): Promise<ResponseT> => {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: { 'Content-Type': 'charset=utf-8' },
        redirect: 'follow',
        referrer: 'no-referrer',
        credentials: withCredentials ? 'include' : undefined,
      });
      let responseJson: ResponseT;
      try {
        responseJson = await response.json();
      } catch (jsonParseError) {
        // TODO(anaheim): Ideally we return the error rather than just pocketing the error.
        console.error(jsonParseError);
        // Return null to fail loudly.
        return null as unknown as ResponseT;
      }
      return responseJson;
    })(),
    requestOptions?.tracingOptions,
  );
}

async function postDataArbitraryContentType(
  url: string,
  contentType: string,
  data: any,
  withCredentials = false,
  extraHeaders: Headers = {},
  timeout?: number,
  useFormData?: boolean,
  requestOptions?: RestRequestOptions,
): Promise<any> {
  return tracer.trace(
    'post_' + safelyGetPathname(url),
    (async (): Promise<any> => {
      // Content type and content length are automatically set by browser when posting
      // form data
      let headers = {};
      if (!useFormData) {
        headers = { 'Content-Type': `${contentType}; charset=utf-8`, ...extraHeaders };
      }

      const controller = new AbortController();
      let id: ReturnType<typeof setTimeout> | undefined;
      if (timeout) {
        id = setTimeout(() => controller.abort(), timeout);
      }

      let body = data;
      if (contentType === 'application/json') {
        body = JSON.stringify(data);
      }

      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers,
        redirect: 'follow',
        referrer: 'no-referrer',
        body: body,
        credentials: withCredentials ? 'include' : undefined,
        signal: controller.signal,
      });
      clearTimeout(id as any /* Fixme: http://tiny.cc/r4g9vz */);
      return response.json();
    })(),
    requestOptions?.tracingOptions,
  );
}

export async function postData(
  url: string,
  data = {},
  withCredentials = false,
  extraHeaders: Headers = {},
  timeout?: number,
  requestOptions?: RestRequestOptions,
): Promise<any> {
  return postDataArbitraryContentType(
    url,
    'application/json',
    data,
    withCredentials,
    extraHeaders,
    timeout,
    undefined,
    requestOptions,
  );
}

export async function postDataAsFormData(
  url: string,
  data = {},
  withCredentials = false,
  timeout?: number,
  requestOptions?: RestRequestOptions,
): Promise<any> {
  return postDataArbitraryContentType(
    url,
    '',
    data,
    withCredentials,
    {},
    timeout,
    true,
    requestOptions,
  );
}

export async function postDataAsOctetStream(
  url: string,
  data: any,
  withCredentials = false,
  extraHeaders: Headers = {},
  timeout?: number,
  requestOptions?: RestRequestOptions,
): Promise<any> {
  return postDataArbitraryContentType(
    url,
    'application/octet-stream',
    data,
    withCredentials,
    extraHeaders,
    timeout,
    undefined,
    requestOptions,
  );
}

export function getUsername(email: string): string {
  if (!email) {
    return '';
  }
  if (email.indexOf('@') === -1) {
    return email;
  }
  return email.substring(0, email.indexOf('@'));
}

export function isAppliedUser(): boolean {
  return getEmailFromAuthTokenCookie().endsWith('applied.co');
}
