import uuidv4 from 'uuid/v4';

type Tags = Record<string, any>;

export type SpanOverrideProps = {
  startMs?: number;
  spanId?: string;
};

export class Span {
  readonly startMs: number;
  endMs: number | undefined;
  readonly traceId: string;
  readonly spanId: string;
  canceled = false;
  private children: Span[] = [];

  constructor(
    public name: string,
    private tags: Tags = {},
    private parent?: Span,
    overrides?: SpanOverrideProps,
  ) {
    this.startMs = overrides?.startMs ?? Date.now();

    this.traceId = parent?.traceId ?? uuidv4();
    this.spanId = overrides?.spanId ?? uuidv4();

    // Chrome profiler.
    if (typeof performance?.mark === 'function') {
      performance.mark(name + '-start');
    }
  }

  /**
   * `start` creates and returns a new child span. Spans are always started on
   * creation.
   */
  start(name: string, overrides?: SpanOverrideProps): Span {
    const span = new Span(name, {}, this, overrides);
    this.children.push(span);
    return span;
  }

  /**
   * `cancel` cancels the current span, all of its children, and removes this
   * span from its parent, if relevant.
   */
  cancel(): void {
    if (this.endMs !== undefined || this.canceled) {
      // These conditions should never align. To debug issues with spans, add a
      // log here.
      return;
    }

    this.endMs = this.startMs;
    this.canceled = true;

    // Remove this span from the parent's spans, too.
    for (const child of this.children) {
      child.cancel();
    }
    this.parent?.children.splice(this.parent.children.indexOf(this), 1);
  }

  /**
   * `end` finishes the span. The caller may optionally specify a specific end
   * time, otherwise `Date.now()` is used.
   */
  end(atMs?: number): void {
    if (this.endMs !== undefined || this.canceled) {
      // These conditions should never align. To debug issues with spans, add a
      // log here.
      return;
    }

    this.endMs = atMs ?? Date.now();

    // Chrome profiler. Guarding against these methods being undefined in test,
    // anywhere else.
    if (typeof performance?.mark === 'function') {
      performance.mark(this.name + '-end');
    }
    if (typeof performance?.measure === 'function') {
      performance.measure(this.name, this.name + '-start', this.name + '-end');
    }
  }

  /**
   * `setTag` adds metadata to the span.
   */
  setTag(key: string, value: any): void {
    if (value === undefined) {
      delete this.tags[key];
      return;
    }
    this.tags[key] = value;
  }

  /**
   * `getError` returns the span's error, if one occurred.
   */
  getError(): string | undefined {
    return this.tags.error;
  }

  /**
   * `getChildren` returns the spans children, which should not be modified. To
   * create a new child span, use `start`.
   */
  getChildren(): readonly Span[] {
    return this.children;
  }
}
