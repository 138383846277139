export enum ModalResponse {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
}

/**
 * A promise for pausing in the middle of a block code to prompt the user
 * for input, and then resolve once they accept/reject the prompt.
 */
export default class ModalPromise<T = any> {
  public promise: Promise<T>;
  public resolve!: /* Fixme: http://tiny.cc/r4g9vz */ (value?: T | PromiseLike<T>) => void;
  public reject!: /* Fixme: http://tiny.cc/r4g9vz */ (reason?: any) => void;

  public constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
